.autoPaginationPanel {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eceeef;
  border-top: 1px solid #eceeef;
  margin: 30px 0 0 0;
  font-size: 14px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flagBlock {
  background-color: rgb(33, 150, 243);
  border-radius: 5px;
  padding: 3px 6px 3px 3px;
  transition: 0.3s;
  color: #fff;
  opacity: 0.8;
}

.flagBlock.warning {
  background-color: rgb(255, 152, 0);
}

.examGraderContainer {
  overflow: hidden;
}