.no-horizontal-padding {
  padding-left: 0px;
  padding-right: 0px;
  border-left-style: solid;
  border-left-color: #eceeef; 
  border-left-width: 2px; 
}

.needs-grading-header {
  margin-top: 10px;
  margin-bottom: 2px;
  text-indent: 15px;
}

.needs-grading-underline {
  margin-top: 2px;
}

#examContentDiv{
  margin: 0 auto;
}