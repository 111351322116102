body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-content-wrapper {
  padding: 0 0 10px 0 !important;
}

.logo {
  height: 50px;
  width: 50px;
}

.spinner {
  display: inline-block;
  height: 50px;
  width: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.close {
  float: right;
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  position: static !important;
  margin: 0 20px 0 35px;
}

.close:focus {
  outline: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.alertContainer {
  position: fixed;
  bottom: 0;
  right: 30px;
  z-index: 100000;
}

.alertModal {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
  font-weight: 400;
  line-height: 1;
  padding: 10px 10px 10px 20px !important;
}

.alert-dismissible .close {
  padding: 0 !important;
}

#authElement {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.btn-wrapper button {
  width: 32%;
  margin: 0 !important;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  cursor: pointer;
  align-items: flex-start;
  background-color: rgba(236, 238, 239);
  border-color: rgba(0, 123, 255);
  border-radius: 3.75px;
  border-style: solid;
  border-width: 1px;
  color: rgb(0, 123, 255);
  display: inline-block;
  height: 35.25px;
  margin: 0 auto;
  padding-left: 11.25px;
  padding-right: 11.25px;
  text-align: center;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  vertical-align: middle;
}

.btn-wrapper button:hover {
  background-color: rgba(0, 123, 255);
  color: #fff;
}

.btn-wrapper button:disabled {
  cursor: not-allowed;
  align-items: flex-start;
  background-color: rgba(211, 211, 211);
  border-color: rgba(223, 123, 123);
  border-radius: 3.75px;
  border-style: solid;
  border-width: 1px;
  color: rgb(123, 123, 123);
  display: inline-block;
  height: 35.25px;
  margin: 0 auto;
  padding-left: 11.25px;
  padding-right: 11.25px;
  text-align: center;
  vertical-align: middle;
}

.examTitle,
.examsNumber {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
  margin: 15px 0 15px 0;
}

.exam-container .examGradeTitle {
  text-align: center;
  position: relative;
}

.exam-container .examGradeTitle::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #eceeef;
  left: 0;
  bottom: -3px;
}

.zoomBlock div {
  width: 100% !important;
}

#examContentDiv {
  width: 100%;
}

/* Carousel dots */
.dot {
  border-radius: 2px !important;
  width: 20px !important;
  height: 6px !important;
}

@media(max-width: 1000px) {

  #examContentDiv,
  .no-horizontal-padding {
    max-width: unset !important;
    flex: none !important;
  }

  .main-content-row {
    flex-direction: column-reverse;
  }

  .exam-grader-container {
    width: 47%;
    margin: 0 !important;
  }

  .exam-grader-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}