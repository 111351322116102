.navbar {
	background-color: #eceeef;
	display: block;
}

.menu-item {
	margin-top: 5px;
}

.loginButton {
	width: 100px !important;
}

.vertical-line {
	border-left: thick solid black;
}

.informational-message {
	color: rgb(192, 59, 50);
	text-align: center;
}

.informational-purpose{
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
}
.spanArrowBtn{
	font-size: 12px;
	margin: 1px 0 0 5px;
}
.navbar-row{
	align-items: center;
}