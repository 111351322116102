.login {
  width: 1200px;
  max-width: 90%;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.logo-dxs {
  width: 50%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px;
}

.logo-dxs img {
  max-width: 100%;
}

.form {
  margin: 0 auto;
  width: 20%;
  box-sizing: border-box;
  padding-left: 30px;
}

.form-input {
  margin-top: 20px;
}

.error-login {
  height: 0;
  color: salmon;
}

@media screen and (max-width: 850px) {
  .login {
    display: block;
  }

  .logo-dxs {
    width: 100%;
  }

  .form {
    width: 100%;
    padding-left: 0px;
  }

  .form-input {
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .error-login {
    text-align: center;
  }

}