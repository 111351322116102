.examViewerTools {
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.tolls {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tollsContainer {
  width: 80%;
  margin: 0 auto 10px;
  display: flex;
  padding: 0 5%;
  justify-content: space-between;
}
.separators{
  display: flex;
}
.separator {
  position: relative;
  padding: 0 20px;
}

.separator:first-of-type:before,
.separator:last-of-type:before {
  position: absolute;
  content: '';
  height: 70%;
  width: 1px;
  background-color: #eceeef;
  top: 10%;
  left: -5px;
}

.separator:first-of-type:after,
.separator:last-of-type:after {
  position: absolute;
  content: '';
  height: 70%;
  top: 10%;
  width: 1px;
  background-color: #eceeef;
  right: 5px;
}