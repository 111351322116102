.grayScale {
  filter: grayscale(1);
}

.zoomBlock div div {
  width: 100%;
  height: 100%;
}

.carousel {
  width: 80%;
  max-width: 1120px;
  height: 600px !important;
  margin: 0 0 0 0 !important;
  background-color: black;
  border-radius: 7px;
}

.carousel.carousel-slider {
  width: 100% !important;
}