.hotkey-button {
  margin-right: 15px;
  background-color: #eceeef;
}

.hotkey-button:hover {
  background-color: #0275d8;
}

.hotkey-button:selected {
  background-color: #0275d8;
}

.content-border {
  border-style: solid;
  border-color: #eceeef;
  border-width: 2px;
  border-radius: 10px;
}

.list-element {
  margin-top: 10px;
  margin-left: 10px;
}

.main-section {
  margin-top: 15px;
}

.backdrop {
  opacity: .3;
  background-color: #292b2c;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.modalContainer {
  position: absolute;
  width: 30%;
  top: 50px;
  background-color: #fff;
  left: 35%;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}